import api from "api";
import React, { useState, useEffect, useCallback } from "react";
import { List, Avatar, Button, Input, Tooltip, message } from "antd";
import { MessageFilled, CommentOutlined } from "@ant-design/icons";
import {
  SearchOutlined,
  SyncOutlined,
  FilterOutlined,
  SettingOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  TagOutlined,
  EllipsisOutlined,
  PhoneFilled,
  EnvironmentFilled,
} from "@ant-design/icons";
import moment from "moment";
import "./style.css";

const CommentItem = (props) => {
  const { selectedComment, data, setting, isExpand, onClick } = props;

  const clickItem = (item) => {
    if (item.id !== selectedComment?.id) {
      item.page_token = data.page_token;
      props.onClick(item);
    }
  };

  return (
    <div className="custom-list-item" onClick={() => clickItem(data)}>
      <div className="avatar-section">
        <div style={{ position: "relative" }}>
          <Avatar src={`https://graph.facebook.com/${data.userid}/picture?height=100&width=100&access_token=${data.page_token}`} size="large" />
          {data.typeof === "inbox" && (
            <CommentOutlined
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                fontSize: "15px",
                color: "#0084FF",
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "2px",
              }}
            />
          )}
        </div>
      </div>

      <div className="content-section">
        <div className="user-name">{data.username}</div>
        <div className="user-message">{data.childtext}</div>
      </div>
      <div className="action-section">
        {/* Use timeFormated here instead of data.time */}
        <div className="message-time">
          {moment(data.time).add(7, "hours").isSame(moment(), "day")
            ? moment(data.time).add(7, "hours").format("HH:mm")
            : moment(data.time).add(7, "hours").format("DD-MM HH:mm")}
        </div>
        <div className="action-buttons" style={{ marginTop: "15px" }}>
          <div className="input-icons">
            <Tooltip title="Tùy chọn" placement="top">
              <Avatar
                size={20}
                shape="square"
                style={{ backgroundColor: "#fde3cf", marginRight: "2px" }}
                icon={<EnvironmentFilled style={{ color: "white" }} />}
              />
            </Tooltip>
            <Tooltip title="Gắn nhãn" placement="top">
              <Avatar size={20} shape="square" style={{ backgroundColor: "#87d068" }} icon={<PhoneFilled style={{ color: "white" }} />} />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
