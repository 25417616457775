import React, { useState, useEffect, useRef } from "react";
import CommentItem from "./comment_item";
import InfiniteScroll from "react-infinite-scroller";
import { Spin, Button, Empty, notification } from "antd";
import moment from "moment";


const ListComment = (props) => {
  const [search, setSearch] = useState("");
  const scrollRef = useRef(null);
  const scrollTop = useRef(0);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollTop.current;
    }
  }, [props.lstComment]);

  const handleLoadMore = (page) => {
    props.loadMoreComment(page);
  };

  const getNewCustomer = () => {
    // APIHelper.post("FbAssign/GetNewComment").then((data) => {
    //   notification.success({
    //     message: "sssd",
    //     description:
    //      ""
    //   });
    // });
  };

  const getSortList = () => {
    return [...props.lstComment].sort((a, b) =>
      moment(a.LastUpdateTime).isAfter(b.lastupdatetime) ? -1 : 1
    );
  };

  const onScroll = () => {
    if (scrollRef.current) {
      scrollTop.current = scrollRef.current.scrollTop;
    }
  };

  const lstItem = getSortList().map((item) => (
    <CommentItem
      {...props}
      data={item}
      selectedComment={props.selectedComment}
      key={"list_comment_" + item.id}
      onClick={props.onClick}
    />
  ));

  let scrollHeight = "calc(100vh - 50px)";
//   if (props.isShowGetNewCustomer) {
//     scrollHeight = "calc(100vh - 43px)";
//   }

  return (
    <div>
      <div className="conversation-list">
        <div
          onScroll={onScroll}
          ref={scrollRef}
          className="infinite-conv-list"
          style={{ height: scrollHeight, overflow: "hidden scroll" }}
        >
          <InfiniteScroll
            pageStart={1}
            loadMore={handleLoadMore}
            initialLoad={false}
            hasMore={!props.isEnd}
            useWindow={false}
          >
            {props.isSearching && (
              <div style={{ margin: "5px auto", width: 40 }}>
                <Spin />
              </div>
            )}
            {!props.isSearching && props.lstComment.length === 0 && (
              <Empty
                style={{ height: "50px" }}
                description="Load data"
              />
            )}
            {lstItem}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ListComment;
