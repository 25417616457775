import React from "react";
import { Modal, Form, Input, message, Button, Checkbox, Table, Radio, Tabs, Tag, Card, Space, Row } from "antd";
import api from "api";
import AddressPicker from "./address_picker";
import { formatLongDate, formatCurrency } from "common/Format2";
import { SearchOutlined } from "@ant-design/icons";
import AntTable from "../common/AntTable";
import CustomerLivePopup from "./customer_live_popup";
import { useNavigate } from "react-router-dom";
import JATConfigPopup from "./jat_config_popup";

const { TextArea } = Input;
const CustomerLive = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState("");
  const table = React.useRef();
  const customerLivePopup = React.useRef();
  const jatConfigPopup = React.useRef();
  const navigate = useNavigate();
  const col = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên khách",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              customerLivePopup.current.open(record.id);
            }}
          >
            <div
              style={{
                marginBottom: 5,
              }}
            >
              <span
                style={{
                  background: record.is_goods_bomb ? "#f50505" : record.is_warning ? "#f58d05" : "transparent",
                  padding: "0px 5px 0px 5px",
                  borderRadius: 4,
                }}
              >
                {record.name}
              </span>
            </div>
            {record.is_wholesale ? (
              <div style={{ marginBottom: 5 }}>
                <Tag color="green">Khách sỉ</Tag>
              </div>
            ) : (
              <div style={{ marginBottom: 5 }}>
                <Tag color="blue">Khách lẻ</Tag>
              </div>
            )}
            <div style={{ display: "flex" }}>
              {record.phone ? <img src="/image/phone2.png" style={{ width: 20, height: 20, marginRight: 10 }} /> : <></>}
              {record.address && record.address_code ? <img src="/image/address2.jfif" style={{ width: 20, height: 20, marginRight: 10 }} /> : <></>}
            </div>
          </div>
        );
      },
    },
    {
      title: "Điện thoại",
      dataIndex: "phone",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
    },
  ];

  const getData = () => {
    table.current.reload();
  };
  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <Row justify="end">
          <Space>
            <Input style={{ width: "525px" }} value={value} onChange={(e) => setValue(e.target.value)} />
            <Button
              type={"primary"}
              icon={<SearchOutlined />}
              onClick={() => {
                getData();
              }}
              C
            ></Button>
          </Space>
        </Row>
        <Row justify="end" style={{ marginTop: 15 }}>
          <Space>
            <Button
              type={"primary"}
              onClick={() => {
                customerLivePopup.current.open();
              }}
            >
              Thêm mới
            </Button>
          </Space>
        </Row>
      </Card>
      <Card>
        <AntTable
          columns={col}
          ajax="CustomerLive/LiveCustomerSearchList"
          ref={table}
          customSearch={{
            Info: value,
          }}
        ></AntTable>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
        <Button
          style={{ marginRight: 50 }}
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
      <CustomerLivePopup ref={customerLivePopup} reload={getData}></CustomerLivePopup>
      <JATConfigPopup ref={jatConfigPopup}></JATConfigPopup>
    </>
  );
});
export default CustomerLive;
