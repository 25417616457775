import React, { useState, useEffect } from "react";
import { List, Avatar, Button, Input, Tooltip, Layout, Empty } from "antd";
import api from "api";
import {
  SearchOutlined,
  SyncOutlined,
  FilterOutlined,
  SettingOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  TagOutlined,
  EllipsisOutlined,
  PhoneFilled,
  EnvironmentFilled,
} from "@ant-design/icons";
import "./style.css";
import ListComment from "./list_comment";
import moment from "moment";
import DetailChat from "./detail_chat";
import { ChatData } from "./index";

const { Sider, Content } = Layout;
const Chat = () => {
  const [objectSearch, setObjectSearch] = React.useState({
    pageSelect: [],
    typemessage: "",
    haveread: "",
    havereply: "",
    havephone: "",
    begintime: "",
    endtime: "",
    post: "",
    tag: "",
    search: "",
    pagging: 1,
    curentView: 0,
    userid: "",
    assignto: "",
    beginhour: "00:00",
    endhour: "23:59",
    customergroupid: [],
    interactivecustomer: [],
  });
  const [isSearching, setIsSearching] = React.useState(false);
  const [lstComment, setLstComment] = React.useState([]);
  //const [selectedComment, setSelectedComment] = useState({});
  const { selectedComment, setSelectedComment } = React.useContext(ChatData);
  const getListFBComment = () => {
    const updatedObjectSearch = { ...objectSearch, pagging: 1 };
    setIsSearching(true);
    setLstComment([]);
    setObjectSearch(updatedObjectSearch);
    api.post("Facebook/GetListFBComment", updatedObjectSearch).then((res) => {
      addListComment(res.data);
    });
  };
  const addListComment = (data) => {
    var lst = [...lstComment];
    if (data == null) data = [];

    for (let index = 0; index < data.length; index++) {
      var cm = data[index];
      if (cm.isreply === true) {
        cm.isread = true;
      }
      cm.childtext = processChildText(cm.childtext);
      if (cm.lastupdatetime) {
        cm.lastupdatetime = cm.lastupdatetime.replace("Z", "");
      }

      var pos = lst.findIndex((item) => item.id === cm.id);
      if (pos === -1) {
        lst.push(cm);
      }
    }
    var lstSort = getSortList(lst);
    setLstComment(lstSort);
    setIsSearching(false);
  };

  const getSortList = (lst) => {
    var lstNew = lst.map(function (item, index) {
      var it = { ...item };
      if (it.isreply == true) {
        it.isread = true;
      }
      return it;
    });
    return lstNew.sort((a, b) => {
      if ((a.isread == false ? 0 : 1) < (b.isread == false ? 0 : 1)) {
        return -1;
      } else {
        if ((a.IsRead == false ? 0 : 1) == (b.IsRead == false ? 0 : 1)) {
          if (moment(a.lastupdatetime).isAfter(b.lastupdatetime)) {
            return -1;
          }
        }
      }

      return 1;
    });
  };

  const processChildText = (text) => {
    if (text == null) return null;
    text = text.replace("<br/>", "");
    var patt = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/g;
    var links = text.match(patt);
    if (links) {
      links.forEach((a) => {
        text = text.replace(a, " [link] ");
      });
    }

    patt = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    links = text.match(patt);
    if (links) {
      links.forEach((a) => {
        text = text.replace(a, " [link] ");
      });
    }

    return text;
  };
  const loadMoreComment = (page) => {};

  const onItemSelected = (item) => {
    setSelectedComment(item);
  };

  const onReply = (replyData) => {};

  useEffect(() => {
    getListFBComment();
  }, []);
  return (
    <>
      <div className="user-list-container">
        <div className="user-list-header">
          <Input placeholder="Tìm kiếm theo họ tên, SĐT" prefix={<SearchOutlined />} className="search-input" onPressEnter={getListFBComment} />
          <Button icon={<SyncOutlined />} type="text" className="action-icon-header" />
          <Button icon={<FilterOutlined />} type="text" className="action-icon-header" />
          <Button icon={<SettingOutlined />} type="text" className="action-icon-header" />
        </div>

        <div className="scrollable-list">
          {/* <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item className="custom-list-item">

              <div className="avatar-section">
                <Avatar src={item.avatar} size="large" />
              </div>

              <div className="content-section">
                <div className="user-name">{item.name}</div>
                <div className="user-message">{item.message}</div>
              </div>
              <div className="action-section">
                <div className="message-time">{item.time}</div>
                <div className="action-buttons" style={{ marginTop: "15px" }}>
                  <div className="input-icons">
                    <Tooltip title="Tùy chọn" placement="top">
                      <Avatar
                        size={20}
                        shape="square"
                        style={{ backgroundColor: "#fde3cf", marginRight: "2px" }}
                        icon={<EnvironmentFilled style={{ color: "white" }} />}
                      />
                    </Tooltip>
                    <Tooltip title="Gắn nhãn" placement="top">
                      <Avatar size={20} shape="square" style={{ backgroundColor: "#87d068" }} icon={<PhoneFilled style={{ color: "white" }} />} />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        /> */}
          <ListComment
            itemLayout="horizontal"
            lstComment={lstComment}
            selectedComment={selectedComment}
            isSearching={isSearching}
            onClick={onItemSelected}
            onReply={onReply}
            loadMoreComment={loadMoreComment}
          />
        </div>
      </div>

      <Content style={{ background: "#f0f2f5" }}>
        {selectedComment && selectedComment.id ? (
          <DetailChat selectedComment={selectedComment} />
        ) : (
          <Empty style={{ height: "50px" }} description="Chọn Comment hoặc Inbox" />
        )}
      </Content>
    </>
  );
};

export default Chat;
