import React, { useState } from "react";
import { Card, Row, Col, Avatar, Typography, Divider, Button, Tag, Input, Space, Tabs, Dropdown, Menu, Select, Collapse, Badge, Skeleton } from "antd";
import {
  EditOutlined,
  PhoneOutlined,
  PhoneFilled,
  EnvironmentFilled,
  DownOutlined,
  UserOutlined,
  SearchOutlined,
  FileTextOutlined,
  ShoppingCartOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ChatData } from "../index";
import api from "api";
import "./style.css";
import CustomerChat from "./CustomerChat";

const { Panel } = Collapse;
const { Text, Title, Link } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const RightChat = () => {
  const [status, setStatus] = useState("1");
  const [customer, setCustomer] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const { selectedComment } = React.useContext(ChatData);
  console.log("selectedComment", selectedComment);

  const tabs = [
    {
      key: "1",
      label: "Khách hàng",
    },
    {
      key: "2",
      label: "PBH",
      //disabled: customer == null,
    },
  ];

  React.useEffect(() => {
    getCustomer();
  }, [selectedComment]);

  const getCustomer = () => {
    if (selectedComment != null)
      api.get("CustomerLive/GetByFBUserID?id=" + selectedComment.user).then((res) => {
        setCustomer(res.data);
      });
  };

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <div style={{ marginBottom: 16 }} className="right-chat">
        <Tabs activeKey={activeKey} centered onChange={setActiveKey} items={tabs} style={{ background: "#fff" }}></Tabs>
        {activeKey == "1" ? (
          <CustomerChat customer={customer} />
        ) : (
          <>
            <div style={{ padding: "16px" }}>
              <Row align="middle" style={{ marginBottom: "16px" }}>
                <Col span={2}>
                  <Avatar size={40} icon={<UserOutlined />} />
                </Col>
                <Col span={10}>
                  <div>
                    <Text strong>Vũ Dung</Text>
                    <br />
                    <Text type="secondary">Người bán</Text>
                    <Link style={{ marginLeft: 8 }}>Lê Hoàng Hùng</Link>
                  </div>
                </Col>
                <Col span={12}>
                  <Input placeholder="Ghi chú" />
                </Col>
              </Row>
              <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Collapse.Panel header="Sản phẩm" key="1" extra={<Text>Kho hàng: [WH] rainbow</Text>}>
                  <Input placeholder="Tìm kiếm sản phẩm" prefix={<SearchOutlined />} style={{ marginBottom: "16px" }} />
                  <div style={{ textAlign: "center", margin: "16px 0" }}>
                    <ShoppingCartOutlined style={{ fontSize: "48px", color: "#bfbfbf" }} />
                    <Text type="secondary" block>
                      Chưa có sản phẩm nào
                    </Text>
                  </div>
                  <div
                    style={{
                      padding: "8px",
                      backgroundColor: "#fafafa",
                      borderRadius: "4px",
                      marginTop: "16px",
                    }}
                  >
                    <Row justify="space-between">
                      <Text>Tổng</Text>
                      <Text>0 ₫</Text>
                    </Row>
                    <Row justify="space-between">
                      <Text>Tổng tiền</Text>
                      <Text>0 ₫</Text>
                    </Row>
                    <Row justify="space-between">
                      <Text>Trả trước</Text>
                      <Input type="number" placeholder="0" style={{ width: "100px" }} />
                    </Row>
                  </div>
                </Collapse.Panel>
              </Collapse>
              <Collapse defaultActiveKey={["1"]} style={{ marginTop: "16px" }} expandIconPosition="right">
                <Collapse.Panel header="Giao hàng" key="1">
                  <Input placeholder="Tìm kiếm địa chỉ" prefix={<EnvironmentOutlined />} style={{ marginBottom: "16px" }} />
                  <Input placeholder="Số điện thoại giao hàng" defaultValue="0987654321" prefix={<PhoneFilled />} style={{ marginBottom: "8px" }} />
                  <Text type="secondary">Tỉ lệ giao thành công: 83% (15/18)</Text>
                  <br />
                  <Text link style={{ marginTop: "8px", display: "block" }}>
                    Tìm thấy 5 địa chỉ
                  </Text>

                  <Select placeholder="Chọn đối tác giao hàng" style={{ width: "100%", marginTop: "16px" }}>
                    <Option value="1">Giao hàng nhanh</Option>
                    <Option value="2">Giao hàng tiết kiệm</Option>
                  </Select>
                </Collapse.Panel>
              </Collapse>
              <Collapse defaultActiveKey={["1"]} style={{ marginTop: "16px" }} expandIconPosition="right">
                <Collapse.Panel header="Giao hàng" key="1">
                  <Input placeholder="Tìm kiếm địa chỉ" prefix={<EnvironmentOutlined />} style={{ marginBottom: "16px" }} />
                  <Input placeholder="Số điện thoại giao hàng" defaultValue="0987654321" prefix={<PhoneFilled />} style={{ marginBottom: "8px" }} />
                  <Text type="secondary">Tỉ lệ giao thành công: 83% (15/18)</Text>
                  <br />
                  <Text link style={{ marginTop: "8px", display: "block" }}>
                    Tìm thấy 5 địa chỉ
                  </Text>

                  <Select placeholder="Chọn đối tác giao hàng" style={{ width: "100%", marginTop: "16px" }}>
                    <Option value="1">Giao hàng nhanh</Option>
                    <Option value="2">Giao hàng tiết kiệm</Option>
                  </Select>
                </Collapse.Panel>
              </Collapse>
            </div>
            <div
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "16px",
                background: "#ffffff",
                boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                zIndex: 999,
                zIndex: 10,
                background: "#fff",
              }}
            >
              <Row justify="space-between">
                <Col>
                  <Button icon={<SaveOutlined />} type="primary">
                    Lưu
                  </Button>
                  <Button style={{ marginLeft: "8px" }} icon={<FileTextOutlined />}>
                    Tạo mới
                  </Button>
                  <Button style={{ marginLeft: "8px" }} icon={<FileDoneOutlined />}>
                    Xác nhận
                  </Button>
                  <Button style={{ marginLeft: "8px" }} icon={<EnvironmentOutlined />} type="default">
                    Địa chỉ
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RightChat;
