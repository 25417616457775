import { Table, Card, Select, Button, Space, Popconfirm, message, Input, Checkbox, Tag, Modal } from "antd";
import React from "react";
import api from "api";
import { formatLongDate, formatCurrency } from "common/Format2";
import { SwapOutlined } from "@ant-design/icons";

const ChangeSession = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderID] = React.useState();
  const [lstSession, setLstSession] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [data, setData] = React.useState([]);
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setOrderID(id);
    },
  }));
  React.useEffect(() => {
    api.get("LiveOrder/GetListSession").then((res) => {
      var data = res.lst.map((item) => {
        return {
          value: item.id,
          label: `Phiên live ${formatLongDate(item.start_time)} - ${item.end_time ? formatLongDate(item.end_time) : "Chưa kết thúc"}`,
        };
      });
      setLstSession(data);
    });
  }, []);

  React.useEffect(() => {
    getData();
  }, [value]);
  const getData = () => {
    api.get(`LiveOrder/GetOrders?session_id=${value}&info=`).then((res) => {
      setData(res.lst.filter((x) => x.id != orderID));
    });
  };

  const close = () => {
    props.reload();
    setValue(null);
    setVisible(false);
  };

  const saveNote = () => {
    api.post(`LiveOrder/UpdateNote?id=${orderID}&note=%C4%90%E1%BB%A3i%20d%E1%BB%93n%20%C4%91%C6%A1n`).then((res) => {
      message.success("Thành công");
      close();
    });
  };

  const changeSession = (id) => {
    api.post(`LiveOrder/UpdateSession?id=${orderID}&session_id=${value}&new_id=${id}`).then((res) => {
      message.success("Thành công");
      close();
    });
  };

  const columns = [
    {
      title: "Tên khách",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text, record, index) => {
        if (record.customer_id) {
          return record.customer_name2;
        } else {
          return record.customer_name;
        }
      },
    },
    {
      title: "Sản phẩm",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        return (
          <div>
            {text.split(",").map((item) => (
              <p>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "SL",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        var products = record.products.split(", ");
        if (products.length == 1) products = record.products.split(",");
        var count = 0;
        products.forEach((x) => {
          var lstSplit = x.split(" ");
          count += lstSplit[0] * 1;
        });
        return <div>{count}</div>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return formatCurrency(text);
      },
    },
    {
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return (
          <Button
            icon={<SwapOutlined />}
            onClick={() => {
              changeSession(record.id);
            }}
          ></Button>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        maskClosable={false}
        width={600}
        open={visible}
        title={"Dồn đơn"}
        onCancel={() => {
          close();
        }}
        footer={[
          <Button
            loading={loading}
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              saveNote();
            }}
          >
            Đợi dồn đơn
          </Button>,
          // <Button
          //   type="primary"
          //   loading={loading}
          //   onClick={() => {
          //     changeSession();
          //   }}
          // >
          //   Dồn đơn
          // </Button>,
        ]}
      >
        <Card style={{ marginBottom: 20 }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"chọn phiên live"}
            options={lstSession}
            value={value}
            onChange={(value) => {
              setValue(value);
            }}
          />
        </Card>
        <Table dataSource={data} columns={columns} />
      </Modal>
    </div>
  );
});
export default ChangeSession;
