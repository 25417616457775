import React from 'react';
import { Menu } from 'antd';
import {
  MessageOutlined,
  PhoneOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import './style.css'; 

const SidebarMenu = () => {
  return (
    <Menu
      mode="vertical"
      defaultSelectedKeys={['1']}
      theme="light"
      className="icon-only-menu"
    >
      <Menu.Item key="1" icon={<MessageOutlined />} />
      <Menu.Item key="2" icon={<PhoneOutlined />} />
      <Menu.Item key="3" icon={<SettingOutlined />} />
    </Menu>
  );
};

export default SidebarMenu;
