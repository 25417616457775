import React from "react";
import { Layout } from "antd";
import SidebarMenu from "./SidebarMenu";
import Chat from "./chat";

import RightChat from "./RightChat";

const { Sider, Content } = Layout;

export const ChatData = React.createContext();
const FbChat = () => {
  const [selectedComment, setSelectedComment] = React.useState();
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <ChatData.Provider value={{ selectedComment, setSelectedComment }}>
      <Layout style={{ height: "100vh" }}>
        <Sider width={80} style={{ background: "#001529" }}>
          <SidebarMenu />
        </Sider>
        <Chat />

        <Sider width={500} style={{ background: "#fff", overflowY: "auto" }}>
          <RightChat />
        </Sider>
      </Layout>
    </ChatData.Provider>
  );
};

export default FbChat;
