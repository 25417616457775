import React from "react";
import { Card, Row, Col, Avatar, Form, Divider, Button, Checkbox, Input, Radio, Typography, Dropdown, Menu, Select, Collapse, Badge, Skeleton } from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  PhoneFilled,
  EnvironmentFilled,
  DownOutlined,
  UserOutlined,
  SearchOutlined,
  FileTextOutlined,
  ShoppingCartOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ChatData } from "../index";
import api from "api";
import AddressPicker from "component/live/address_picker";

const { Text, Title, Link } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const CustomerChat = (props) => {
  const [mode, setMode] = React.useState("edit");
  const [lstOrder, setLstOrder] = React.useState([]);
  const [form] = Form.useForm();
  const { selectedComment } = React.useContext(ChatData);

  const menu = (
    <Menu>
      <Menu.Item>
        <Text>Doanh số đầu kỳ: 0đ</Text>
      </Menu.Item>
      <Menu.Item>
        <Text>Doanh số: 1000đ</Text>
      </Menu.Item>
      <Menu.Item>
        <Text>Tổng doanh số: 1000đ</Text>
      </Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    if (props.customer == null) {
      setMode("edit");
      form.setFieldsValue({ name: selectedComment?.username });
    }
  }, [props.customer]);

  return (
    <div>
      <div style={{ padding: "10px 10px 10px 10px", backgroundColor: "#fff", marginBottom: 10 }}>
        <div>
          {mode == "edit" && props.customer != null ? (
            <Button type="primary" shape="round" icon={<ArrowLeftOutlined />} size={"small"} style={{ marginBottom: 10 }}>
              Trở lại
            </Button>
          ) : (
            <></>
          )}
        </div>
        <Row align="middle">
          <Avatar
            size={54}
            src={`https://graph.facebook.com/${selectedComment?.userid}/picture?height=54&width=54&access_token=${selectedComment?.page_token}`}
          />
          <div style={{ marginLeft: 16 }}>
            <Row align="middle" justify="space-between">
              <Title level={5} style={{ margin: 0, display: "inline-block" }}>
                {props.customer ? props.customer?.name : selectedComment?.username}
                {props.customer ? <Button icon={<EditOutlined />} type="link" style={{ marginLeft: "8px" }} /> : <></>}
              </Title>
            </Row>
            {props.customer ? (
              <>
                <Row align="middle">
                  <div style={{ marginLeft: "12px" }}>
                    <Text>ID: {props.customer.id}</Text> | <Text>Mã: N/A</Text>
                  </div>
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
        </Row>
      </div>

      <div style={{ padding: 10, marginBottom: 10, backgroundColor: "#fff" }} hidden={mode != "edit"}>
        <Form form={form} layout="vertical">
          <Form.Item hidden={true} name={"id"} />
          <div style={{ display: "flex", gap: 20 }}>
            <Form.Item
              style={{ flex: 1 }}
              name="name"
              label="Tên khách"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="phone" label="Số điện thoại" style={{ flex: 1 }}>
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="note" label="Ghi chú">
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item name={"address_code"} label={"Địa chỉ"}>
            <AddressPicker />
          </Form.Item>
          <Form.Item name="address">
            <Input placeholder="Địa chỉ" />
          </Form.Item>
          <div style={{ display: "flex", gap: 50 }}>
            <Form.Item name={"is_wholesale"}>
              <Radio.Group>
                <Radio value={true}> Khách sỉ </Radio>
                <Radio value={false}> Khách lẻ </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item valuePropName="checked" name={"is_warning"}>
              <Checkbox>Cảnh báo</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name={"is_goods_bomb"}>
              <Checkbox>Bom hàng</Checkbox>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      <div style={{ paddingLeft: "10px", borderBottom: "2px solid #f0f0f0" }} hidden={mode == "edit"}>
        <Row style={{ marginTop: 8 }}>
          <Col span={24}>
            <Text>
              <Avatar size={20} shape="square" style={{ backgroundColor: "#f0f0f0" }} icon={<PhoneFilled style={{ color: "#b3b3b3" }} />}></Avatar> 099898899
            </Text>
            <Text style={{ fontSize: "12px" }} type="secondary">
              {" "}
              Tỉ lệ giao thành công: 83% (15/18)
            </Text>
            <br />
            <Text italic>
              <Avatar
                size={20}
                shape="square"
                style={{ backgroundColor: "#f0f0f0", marginRight: "2px" }}
                icon={<EnvironmentFilled style={{ color: "#b3b3b3" }} />}
              />{" "}
              Chưa có địa chỉ
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: 8 }}>
          <Col>
            <div
              style={{
                display: "inline-block",
                padding: "4px 12px",
                border: "1px solid #d9d9d9",
                borderRadius: "30px",
                backgroundColor: "#fff",
              }}
            >
              <Text strong>Tổng doanh số: </Text>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="link" style={{ color: "green" }}>
                  1.000 ₫ <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <div>
          <Collapse expandIconPosition="right" style={{ border: "none", background: "none", borderBottom: "2px solid #f0f0f0", borderRadius: "0px" }}>
            <Panel
              header={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Phiếu bán hàng
                    <Badge count={1} style={{ backgroundColor: "#52c41a", marginLeft: "8px" }} />
                  </span>
                  <Button type="link" style={{ color: "#52c41a" }}>
                    Đã xác nhận 1
                  </Button>
                </div>
              }
              key="1"
              style={{ border: "none", background: "none" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Link href="#INV/2024/0001">#INV/2024/0001</Link>
                <div>1.000 ₫</div>
                <Button type="link" icon={<EditOutlined />} />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4px" }}>
                <Text type="secondary">19:51, 29/09/2024</Text>
                <PhoneFilled style={{ color: "#52c41a" }} />
              </div>
            </Panel>
          </Collapse>
          <Collapse expandIconPosition="right" style={{ border: "none", background: "none", borderBottom: "2px solid #f0f0f0", borderRadius: "0px" }}>
            <Panel header={<div>Ghi chú</div>} key="2" style={{ border: "none", background: "none" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Input placeholder="Nhập ghi chú" style={{ width: "85%" }} />
                <Button type="primary" style={{ marginLeft: "8px" }}>
                  + Thêm
                </Button>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
export default CustomerChat;
