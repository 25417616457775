import React from "react";
import { Modal, Form, Input, message, Button, Checkbox, Table, Radio, Tabs, Tag } from "antd";
import api from "api";
import AddressPicker from "./address_picker";
import { formatLongDate, formatCurrency } from "common/Format2";

const { TextArea } = Input;
const CustomerLivePopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderID] = React.useState();
  const [tabKey, setTabKey] = React.useState("info");
  const [lstOrder, setListOrder] = React.useState([]);
  const [data, setData] = React.useState();
  const form = React.useRef();
  React.useImperativeHandle(ref, () => ({
    open(id, orderID) {
      setOrderID(orderID);
      setVisible(true);
      if (id) {
        setTimeout(() => {
          api.get("CustomerLive/GetByID?id=" + id).then((res) => {
            setData(res.data);
            if (form.current) {
              form.current.setFieldsValue({
                is_wholesale: false,
              });
              form.current.setFieldsValue(res.data);
            }
          });
          api.get("LiveOrder/GetOrdersFromCustomerID?id=" + id).then((res) => {
            setListOrder(res.data);
          });
        }, 500);
      } else {
        setListOrder([]);
      }
    },
  }));
  const tabChange = (key) => {
    setTabKey(key);
  };
  const items = [
    {
      key: "info",
      label: "Thông tin",
    },
    {
      key: "order",
      label: "Đơn hàng",
    },
  ];
  const onFinish = (values) => {
    setLoading(true);
    api
      .post("CustomerLive/Save", values)
      .then((res) => {
        message.success("Lưu thành công");
        setVisible(false);
        setLoading(false);
        if (orderID) api.get(`LiveOrder/ChangeCustomer?id=${orderID}&customerID=${data.id}`);
        close();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        setLoading(false);
      });
  };

  const handlePhoneChange = (e) => {
    if (data.phone != e)
      api.get("CustomerLive/GetByPhone?phone=" + e).then((res) => {
        setData(res.data);
        if (form.current) form.current.setFieldsValue(res.data);
      });
  };

  const close = () => {
    if (props.reload) props.reload();
    form.current.resetFields();
    setVisible(false);
  };

  const columns = [
    {
      title: "Sản phẩm",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        return (
          <div>
            {text.split(",").map((item) => (
              <p>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "SL",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        var products = record.products.split(", ");
        if (products.length == 1) products = record.products.split(",");
        var count = 0;
        products.forEach((x) => {
          var lstSplit = x.split(" ");
          count += lstSplit[0] * 1;
        });
        return <div>{count}</div>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return formatCurrency(text);
      },
    },

    {
      title: "Freeship",
      dataIndex: "is_freeship",
      key: "is_freeship",
      render: function (text, record, index) {
        return <div>{record.is_freeship ? "Miễn phí ship" : "Tính phí ship"}</div>;
      },
    },
    {
      title: "Mã vận đơn",
      dataIndex: "transpost_id",
      key: "transpost_id",
    },
    {
      title: "Trạng thái vận chuyển",
      dataIndex: "transpost_status",
      key: "transpost_status",
      render: function (text, record, index) {
        if (record.transpost_status == "created") {
          return <Tag color="green">Đã gửi đơn</Tag>;
        }
        if (record.transpost_status == "cancelled") {
          return <Tag color="orange">Đã hủy đơn</Tag>;
        }
      },
    },
    {
      title: "Phiên live",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>{`Phiên live ${formatLongDate(record.live_start_time)} - ${record.live_end_time ? formatLongDate(record.live_end_time) : "Chưa kết thúc"}`}</div>
        );
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      width={1000}
      open={visible}
      title={"Thông tin khách hàng"}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form.current.submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.current.submit();
          }}
          disabled={tabKey == "order"}
        >
          Lưu
        </Button>,
      ]}
    >
      <Tabs activeKey={tabKey} items={items} onChange={tabChange} />

      <Form layout="vertical" ref={form} onFinish={onFinish} hidden={tabKey == "info" ? false : true}>
        <Form.Item hidden={true} name={"id"} />
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="name"
            label="Tên khách"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Số điện thoại" style={{ flex: 1 }}>
            <Input disabled />
          </Form.Item>
        </div>

        <Form.Item name="note" label="Ghi chú">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name={"address_code"} label={"Địa chỉ"}>
          <AddressPicker />
        </Form.Item>
        <Form.Item name="address">
          <Input placeholder="Địa chỉ" />
        </Form.Item>
        <div style={{ display: "flex", gap: 50 }}>
          <Form.Item name={"is_wholesale"}>
            <Radio.Group>
              <Radio value={true}> Khách sỉ </Radio>
              <Radio value={false}> Khách lẻ </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item valuePropName="checked" name={"is_warning"}>
            <Checkbox>Cảnh báo</Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name={"is_goods_bomb"}>
            <Checkbox>Bom hàng</Checkbox>
          </Form.Item>
        </div>
      </Form>
      <div hidden={tabKey == "order" ? false : true}>
        <Table dataSource={lstOrder} columns={columns} />
      </div>
    </Modal>
  );
});
export default CustomerLivePopup;
