import React, { useState, useEffect, useRef } from "react";
import { Input, Avatar, Button, List, Tooltip, Modal, message, Upload } from "antd";
import api from "api";
import {
  MessageOutlined,
  PhoneOutlined,
  SendOutlined,
  SmileOutlined,
  FileOutlined,
  PictureOutlined,
  EllipsisOutlined,
  TagOutlined,
  EnvironmentFilled,
  PhoneFilled,
} from "@ant-design/icons";
import "./style.css";
import * as FacebookAPI from "./FacebookAPI";
import moment from "moment";
import { imageURL } from "config/index";
import { uploadImageURL } from "config/index";
import axios from "axios";
const DetailChat = (props) => {
  const [lstDetailComment, setLstDetailComment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState(props.selectedComment);
  const [text, setText] = useState("");
  const [fileList, setFileList] = useState([]);
  const chatContentRef = useRef(null);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [lstDetailComment]);
  useEffect(() => {
    if (props.selectedComment.id !== selectedComment.id) {
      setSelectedComment(props.selectedComment);
    }
    loadData();
  }, [props.selectedComment]);
  const loadData = () => {
    const item = props.selectedComment;

    setIsLoading(true);
    setLstDetailComment([]);
    api.get("Facebook/LoadDetailChat?id=" + item.id).then((res) => {
      var data = res.data;
      if (data.length > 0 && data[0].childof === item.id) {
        setLstDetailComment(data);
      }
    });
  };
  const sendReplyComment = () => {
    if (text === "" && fileList.length === 0) {
      message.error({
        message: "Lỗi API",
        description: "Bạn phải nhập nội dung hoặc chọn hình ảnh",
      });
      return;
    }

    if (!selectedComment.page_token) {
      Modal.error({
        title: "Lỗi Token",
        content: "Token không hợp lệ. Vui lòng kiểm tra lại token của bạn.",
      });
      return;
    }

    if (fileList.length === 0) {
      replyText(text);
    } else {
      replyWithImage(text, fileList);
    }
  };
  const replyText = (message, check) => {
    var Token = selectedComment.page_token;
    var cmID = selectedComment.id;
    FacebookAPI.replyFbAPI(cmID, selectedComment.pageid, Token, message, selectedComment.typeof, null, selectedComment.userid).then(
      (res) => {
        var form = {
          PageID: selectedComment.pageid,
          CommentID: selectedComment.id,
          Message: message,
          Type: selectedComment.typeof,
          ReturnID: res.message_id ? res.message_id : res.id,
          WithPhoto: false,
        };
        saveMessageToServer(form);
        console.log(lstDetailComment);
        setText("");
        setFileList([]);
      },
      (error) => {
        error = JSON.parse(error).error;
        Modal.error({
          title: "Notification",
          content: error.message,
        });
      }
    );
  };

  const saveMessageToServer = (form) => {
    api.post("Facebook/SaveReplyComment", form).then((res) => {
      if (res.code === 1) {
        setLstDetailComment((prevComments) => [...prevComments, res.comment]);
      }
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendReplyComment();
    }
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);
  };
  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file) {
          if (file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");
          uploadImage(file);
        }
      }
    }
  };
  const uploadImage = async (file) => {
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true,
    };
    fmData.append("file", file);
    fmData.append("type", "image");
    try {
      const res = await axios.post(uploadImageURL, fmData, config);
      const newFile = {
        uid: fileList.length.toString(),
        name: res.data,
        url: `${imageURL}${res.data}`,
      };

      const newFileList = [newFile, ...fileList];
      setFileList(newFileList);
    } catch (err) {}
  };

  const renderPreviews = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: 8,
        }}
      >
        {fileList.map((file, index) => {
          const url = file.url;
          return (
            <div key={index} style={{ width: "100px", height: "100px" }}>
              <img src={url} alt="preview" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
          );
        })}
      </div>
    );
  };

  const replyWithImage = (message, lstImage, selectedCmt = null) => {
    if (selectedCmt != null) selectedComment = selectedCmt;
    var Token = selectedComment.page_token;
    if (selectedComment.typeof == "inbox") {
      replyInboxWithImage(message, lstImage, Token, selectedCmt);
    } else {
      fileList.forEach((element) => {
        setTimeout(() => {
          reply1Image(message, element, Token);
        }, 500);
      });
    }
  };
  const replyInboxWithImage = (message, images, token, selectedCmt = null) => {
    var cmID = selectedComment.id;
    var tagChat = null;
    if (message != "") {
      FacebookAPI.replyFbAPI(cmID, selectedComment.pageid, token, message, selectedComment.typeof, tagChat, selectedComment.userid).then(
        (res) => {
          var form = {
            PageID: selectedComment.pageid,
            CommentID: selectedComment.id,
            Message: message,
            Type: selectedComment.typeof,
            ReturnID: res.message_id,
            WithPhoto: false,
          };
          saveMessageToServer(form);
          setText("");
          setFileList([]);
        },
        (error) => {
          error = JSON.parse(error).error;

          Modal.error({
            message: "Notification",
            description: "Có lỗi, vui lòng thử lại sau ít phút",
          });
        }
      );
    }
    images.forEach((item, index) => {
      setTimeout(() => {
        FacebookAPI.replyInboxWithPhoto(cmID, selectedComment.pageid, token, "", item, tagChat, selectedComment.userid).then(
          (response) => {
            var form = {
              PageID: selectedComment.pageid,
              CommentID: selectedComment.id,
              Message: "",
              Type: selectedComment.typeof,
              ReturnID: response.message_id,
              WithPhoto: true,
            };
            saveMessageToServer(form);
            setText("");
            setFileList([]);
          },

          (error) => {
            error = JSON.parse(error).error;
            Modal.error({
              message: "Notification",
              description: "Error sending message: " + error.message,
            });
          }
        );
      }, 200 * index);
    });
  };
  const reply1Image = (message, image, token) => {
    var selectedComment = props.selectedComment;
    var item = image;
    var cmID = selectedComment.id;
    FacebookAPI.replyWithPhotoData(cmID, selectedComment.pageid, token, message, item, selectedComment.typeof).then(
      (response) => {
        var form = {
          PageID: selectedComment.pageid,
          CommentID: selectedComment.id,
          Message: message,
          Type: selectedComment.typeof,
          ReturnID: response.id,
          WithPhoto: true,
        };
        saveMessageToServer(form);
        setText("");
        setFileList([]);
      },

      (error) => {
        error = JSON.parse(error).error;

        Modal.error({
          message: "Notification",
          description: "Có lỗi, vui lòng thử lại sau ít phút",
        });

        // if (this.props.sendError) {
        //   this.setState({ tagChat: null });
        //   this.props.sendError(tempID, error.message);
        // }
      }
    );
  };

  return (
    <div className="chat-window-container">
      <div className="chat-header">
        <Avatar
          src={`https://graph.facebook.com/${selectedComment.userid}/picture?height=100&width=100&access_token=${selectedComment.page_token}`}
          size="large"
        />
        <div className="chat-user-info">
          <h3>{selectedComment.username}</h3>
        </div>
        <div className="chat-actions">
          <Button icon={<PhoneOutlined />} type="text" />
          <Button icon={<MessageOutlined />} type="text" />
        </div>
      </div>

      <div className="chat-content" ref={chatContentRef}>
        <List
          dataSource={lstDetailComment}
          renderItem={(item) => (
            <List.Item className={item.userid === item.pageid ? "message right" : "message left"}>
              {item.userid != item.pageid && (
                <Avatar
                  src={`https://graph.facebook.com/${selectedComment.userid}/picture?height=100&width=100&access_token=${selectedComment.page_token}`}
                  size={30}
                  style={{ marginRight: "8px" }}
                />
              )}
              <div className="message-content-wrapper" style={{ padding: "0px" }}>
                <Tooltip
                  title={
                    moment(item.time).add(7, "hours").isSame(moment(), "day")
                      ? moment(item.time).add(7, "hours").format("HH:mm")
                      : moment(item.time).add(7, "hours").format("DD-MM HH:mm")
                  }
                  placement="top"
                >
                  <span className="message-content">
                    {item.previewurl == "video/mp4" ? (
                      <video controls width="200">
                        <source src={item.note} type="video/mp4" />
                      </video>
                    ) : item.previewurl == "audio/mpeg" ? (
                      <audio controls>
                        <source src={item.note} type="audio/mpeg" />
                      </audio>
                    ) : item.previewurl && item.previewurl != "" ? (
                      (() => {
                        const images = item.previewurl.split("\n");
                        return (
                          <div>
                            {images.map((imageUrl) => (
                              <img src={imageUrl} style={{ width: "200px", maxHeight: "200px", objectFit: "cover", borderRadius: "6px" }} />
                            ))}
                          </div>
                        );
                      })()
                    ) : (
                      <p>{item.text}</p>
                    )}
                  </span>
                </Tooltip>
                <div className="hover-buttons">
                  <Tooltip title="Chọn làm số điện thoại" placement="top">
                    <Avatar
                      size={20}
                      shape="square"
                      style={{ backgroundColor: "#87d068", marginRight: "5px", marginLeft: "10px" }}
                      icon={<PhoneFilled style={{ color: "white" }} />}
                    />
                  </Tooltip>
                  <Tooltip title="Chọn làm địa chỉ" placement="top">
                    <Avatar
                      size={20}
                      shape="square"
                      style={{ backgroundColor: "#fde3cf", marginRight: "5px" }}
                      icon={<EnvironmentFilled style={{ color: "white" }} />}
                    />
                  </Tooltip>
                  <Tooltip title="Thêm vào ghi chú" placement="top">
                    <Avatar
                      size={20}
                      shape="square"
                      style={{ backgroundColor: "#fde3cf", marginRight: "5px" }}
                      icon={<EnvironmentFilled style={{ color: "white" }} />}
                    />
                  </Tooltip>

                  {item.sender !== "page" && (
                    <Tooltip title="Thêm vào ghi chú" placement="top">
                      <Avatar
                        size={20}
                        shape="square"
                        style={{ backgroundColor: "#fde3cf", marginRight: "5px" }}
                        icon={<EnvironmentFilled style={{ color: "white" }} />}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className="input-icons">
        <Tooltip title="Tùy chọn" placement="top">
          <Avatar size={30} style={{ backgroundColor: "white", marginRight: "5px" }} icon={<EllipsisOutlined style={{ color: "black" }} />} />
        </Tooltip>
        <Tooltip title="Gắn nhãn" placement="top">
          <Avatar size={30} style={{ backgroundColor: "white" }} icon={<TagOutlined style={{ color: "black" }} />} />
        </Tooltip>
      </div>
      <div className="chat-input-area">
        <div style={{ marginBottom: 8 }}>{renderPreviews()}</div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <textarea
            placeholder="Nhập nội dung tin nhắn, nhấn [Enter] để gửi"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            style={{
              flex: 1,
              height: "100px",
              resize: "none", 
              border: "none",
              outline: "none",
              padding: "8px", 
            }}
          />
          <Button type="primary" icon={<SendOutlined />} onClick={sendReplyComment} style={{ marginLeft: 8 }}>
            Gửi
          </Button>
        </div>
        <div className="input-icons">
          <Button icon={<SmileOutlined />} type="text" />
          <Button icon={<PictureOutlined />} type="text" />
          <Button icon={<FileOutlined />} type="text" />
          <Button icon={<EllipsisOutlined />} type="text" />
        </div>
      </div>
    </div>
  );
};

export default DetailChat;
