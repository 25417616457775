import React from "react";
import { Input, DatePicker, Button, message, Checkbox, Switch } from "antd";
import FacebookLogin from "react-facebook-login";
import api from "api";
import dayjs from "dayjs";

const FbSync = () => {
    const responseFacebook = (response) => {
        if (response.error) {
        } else {
          exchangeToken(response.accessToken);
        }
      };
      

     const exchangeToken = (token) => {
        api.get("Facebook/ExchangeToken?oldToken=" + token).then((res) => {
      
        });
      };
    return (
    <div style={{ textAlign: "center", marginTop: 40 }}>
        <FacebookLogin
            appId="153562967662870"
            version="16.0"
            scope="email,public_profile,pages_manage_metadata,pages_messaging,pages_read_engagement,leads_retrieval,pages_manage_posts,pages_show_list,business_management,page_events,pages_read_user_content,pages_manage_ads,pages_manage_engagement"
            callback={responseFacebook}
        />
    </div>
    )
};

export default FbSync;
